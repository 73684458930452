<template>
  <v-alert
    outlined
    color="teal"
    text
  >
    <div class="mb-2">
      This <strong>{{ segment.type === '1' ? 'static' : 'dynamic' }}</strong> segment contains
      {{ exclude_test_contacts ? 'non-test ' : '' }}contacts that
      {{ segment.criteria.groups && segment.criteria.groups.length > 0 ? 'match the following criteria or ' : '' }}
      were added manually.
    </div>

    <div
      v-if="segment.criteria.groups && segment.criteria.groups.length > 0"
      class="mb-2"
    >
      <div
        v-for="(group, id) in segment.criteria.groups"
        :key="'details-group-' + id"
      >
        <div
          v-if="id > 0"
        >
          {{ segment.criteria.operator }}
        </div>
        <div>
          <span
            v-for="(condition, idx) in group.conditions"
            :key="'details-group' + id + '-condition-' + idx"
          >
            <span
              v-if="idx > 0"
            >
              {{ group.operator }}
            </span>
            {{ getConditionDisplay(condition) }}
          </span>
        </div>
      </div>
    </div>

    <slot name="end_of_alert" />
  </v-alert>
</template>

<script>
export default {
  name: "SegmentDescription",
  props: {
    segment: {
      default: () => {},
      required: true,
    },
    exclude_test_contacts: {
      default: false,
    },
    query_schema: {
      default: () => {},
      required: true,
    }
  },
  methods: {
    getConditionDisplay(condition) {
      if (!this.query_schema) {
        return 'N/A';
      }

      const fieldParts = condition.field.split('.');
      const group = fieldParts.shift();
      const field = fieldParts.join('.');

      if (!this.query_schema[group]?.fields[field]) {
        return 'N/A';
      }

      const fieldDisplay = this.query_schema[group]?.fields[field].label;
      const operator = condition.operator;

      let displayValue = '""';
      if (this.query_schema[group].fields[field].type === "number") {
        displayValue = condition.value;
      } else if (this.query_schema[group].fields[field]?.values) {
        const option = this.query_schema[group].fields[field]?.values.find(elem => elem.value === condition.value);
        if (option) {
          displayValue = '"' + option.label + '"';
        }
      } else {
        displayValue = '"' + condition.value + '"';
      }

      return `${fieldDisplay} ${operator} ${displayValue}`;
    }
  }
}
</script>
