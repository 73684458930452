<template>
  <div
    :class="{
      'd-flex flex-wrap': true,
      'mb-2': $vuetify.breakpoint.mdAndUp,
      'condition-builder-mobile justify-center mb-0':
        $vuetify.breakpoint.smAndDown,
    }"
  >
    <OperatorSelector
      v-if="showOperatorSelector"
      :value="concat"
      @input="$emit('update:concat', $event)"
    />
    <FieldSelector
      :entity.sync="selected_entity"
      :label="selected_field_label"
      :field.sync="shadow_field"
      :entities="available_entities"
      :fields="query_schema"
      :selected_field="available_entity_fields"
    />
    <ConditionSelector
      v-if="(conditions && conditions.length > 0) || selected_condition"
      v-model="selected_condition"
      :items="conditions"
    />
    <component
      :is="input_components_library[selectedFieldTypeValue || 'text']"
      v-if="selectedConditionRequiresValue"
      :key="selectedFieldTypeValue"
      :value="value"
      :items="selected_field_values"
      @input="$emit('update:value', $event === undefined ? '' : $event)"
    />
  </div>
</template>

<script>
/**
 *
 * Text - searches alpha and alphanumeric type fields known as strings

  Operators:

    exact - searches if the field is exactly equal to the value

    partial - searches if the field contains the value somewhere in the stored value


Number - searches numeric type fields

  Operators:

    is equal to

    is not equal to

    is greater than

    is greater than or equal to

    is less than

    is less than or equal to


Choice - searches are limited to a set of choices

  Operators:

    is - searches if the field is equal to the value

    is not - searches if the field is not equal to the value

Allows for a mapping of other allowed values that map to the choices. This is used for the Yes/No search type below. This is mainly to allow for easy use of the API

Yes/No - searches boolean type values that can be yes/no, active/inactive, on/off, true/false

  Operators:

    Same as Choice

  Allowed Values:

    true (Label will say Yes)

    false (Label will say No)

  Other Allowed Values

    y, yes, 1, true will map to Yes

    n, no, 0, false will map to No


Date - searches date type fields

  Operators:

    is - searches an exact date

    is not - searches if the field is not the value

    is before - searches if the field occurs before the value

    is after - searches if the field occurs after the value


Database - extends Choice type but the choices come from the database

  Operators:

    Same as Choice

  Allowed Values:

    The database will be queried for distinct values for the field
 */
import FieldSelector from "@/views/Contacts/components/inputs/FieldSelector";
import ConditionSelector from "@/views/Contacts/components/inputs/ConditionSelector";
import OperatorSelector from "@/views/Contacts/components/inputs/OperatorSelector";
export default {
  name: "ConditionBuilder",
  components: {
    ConditionSelector,
    FieldSelector,
    OperatorSelector,
  },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    query_schema: {
      default: () => ({}),
      type: Object,
    },
    field: {
      default: () => "",
      type: String,
    },
    operator: {
      default: () => "",
      type: String,
    },
    concat: {
      default: () => "AND",
      type: String,
    },
    value: {
      default: () => "",
      /*type: Number || String,*/
    },
    showOperatorSelector: {
      default: () => false,
      type: Boolean,
    },
  },
  data() {
    return {
      input_components_library: {
        text: () => import("@/views/Contacts/components/inputs/ValueInputText"),
        number: () =>
          import("@/views/Contacts/components/inputs/ValueInputNumber"),
        choice: () =>
          import("@/views/Contacts/components/inputs/ChoiceSelector"),
        yes_or_no: () =>
          import("@/views/Contacts/components/inputs/BooleanSelector"),
        date: () => import("@/sharedComponents/DatePicker"),
        database: () =>
          import("@/views/Contacts/components/inputs/DatabaseSelector"),
      },
      selected_entity: "",
      selected_field: "",
      selected_field_label: "",
      selected_field_type: "",
      selected_field_values: [],
      conditions: [],
      operator_types: [],
    };
  },

  computed: {
    selected_value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("update:value", val);
      },
    },
    available_entities: {
      get() {
        return Object.keys(this.query_schema).map((i) => ({
          name: i,
          label: this.query_schema[i].label,
          icon: this.query_schema[i].icon
        }));
      },
    },
    available_entity_fields: {
      get() {
        return this.query_schema[this.selected_entity];
      },
    },
    selected_condition: {
      get() {
        return this.operator;
      },
      set(val) {
        if (this.selected_field_type !== this.operator_types[val]
            && val !== this.selected_condition) {
          this.$emit('update:value', '');
        }
        this.$emit("update:operator", val);
      },
    },
    shadow_field: {
      get() {
        return this.selected_field;
      },
      set(val) {
        if (!val.field) {
          return;
        }
        // TODO: make it function
        this.conditions = val.field.customOperators ?? val.field.operators;
        this.selected_condition = this.conditions[0];
        this.selected_field = val.description;
        this.selected_field_label = val.field.label;
        this.selected_field_type = val.field.type;
        this.selected_field_values = val.field.values;
        this.operator_types = val.field.operatorTypes;

        this.$emit("update:value", "");
        this.$emit(
          "update:field",
          `${this.selected_entity}.${val.description}`
        );
      },
    },
    selectedConditionRequiresValue: {
      get() {
        return this.selected_condition && this.operator_types[this.selected_condition] !== 'empty';
      }
    },
    selectedFieldTypeValue: {
      get() {
        return this.operator_types[this.selected_condition] !== undefined ?
            this.operator_types[this.selected_condition] : this.selected_field_type;
      }
    }
  },
  created() {
    if (this.field) {
      this.selected_field = this.field;

      const fields = this.field.split(".");
      // TODO: make it function
      const smth = this.query_schema[fields[0]].fields[fields[1] === 'customFields' ? `${fields[1]}.${fields[2]}` : fields[1]];
      if (smth) {
        this.conditions = smth.customOperators ?? smth.operators;
        this.selected_field_label = smth.label;
        this.selected_field_type = smth.type;
        this.selected_field_values = smth.values;
        this.operator_types = smth.operatorTypes;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.condition-builder-mobile {
  width: 100%;
}
.w-100 {
  width: 100% !important;
}



::v-deep .date-picker {
  .date-picker-text-field {
    .v-input__slot {
      min-height: 30px !important;
      border-radius: 4px;
      border: solid 1px #dee7ee !important;
      background-color: #ecf1f5 !important;
      margin-bottom: 0;
      padding: 0 0 0 8px !important;
      fieldset {
        border-width: 0;
      }
      input {
        font-family: "Open Sans", sans-serif;
        font-size: 13px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #66788e;
      }
    }
    input {
      padding-top: 3px;
      padding-bottom: 3px;
    }
    .v-input__append-inner {
      margin-top: 3px !important;
      button {
        font-size: 16px;
      }
    }
    .v-text-field__details {
      display: none;
    }
  }
}
</style>
