<template>
  <div class="search-table">
    <v-simple-table>
      <thead>
        <tr class="search-table__header">
          <th v-for="field in tableFields" :key="field.attribute">
            <td
              class="d-flex align-center justify-start"
            >
              <span>
                {{ field.label }}
              </span>
            </td>
          </th>
        </tr>
      </thead>
      <tbody class="search-table__content">
        <tr>
          <td class="text-center" :colspan="tableFields.length">
            <span style="white-space: nowrap;">No Results Found</span>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "NoResultsTable",
  props: {
    tableFields: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {};
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.search-table {
  &__header {
    th {
      font-size: 13px !important;
      color: #241c15 !important;
      font-family: "Open Sans", sans-serif !important;
      td {
        font-size: 13px !important;
        color: #241c15 !important;
        font-family: "Open Sans", sans-serif !important;
      }
    }
  }
  &__content {
    tr td {
      font-size: 13px !important;
      color: #241c15 !important;
      font-family: "Open Sans", sans-serif !important;
      * {
        font-size: 13px;
        color: #241c15;
        font-family: "Open Sans", sans-serif;
      }
    }
  }
}
</style>
