<template>
  <div>
    <slot />
    <div
      :class="{
        'd-flex align-top': true,
        'justify-center w-100': $vuetify.breakpoint.smAndDown,
        'group-buttons-containter-lg': $vuetify.breakpoint.mdAndUp,
      }"
    >
      <v-btn icon @click="$emit('add-condition')">
        <v-icon color="#2b84eb" small>
          mdi-plus
        </v-icon>
      </v-btn>
      <v-btn icon @click="$emit('remove-condition')">
        <v-icon color="red">
          mdi-delete-outline
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConditionGroupBuilder",
  props: {
    showRemoveConditonGroupButton: {
      default: () => {},
      type: Boolean,
    },
    showAddConditonGroupButton: {
      default: () => {},
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.condition-group-buttons {
  width: 100%;
}
.group-buttons-containter-lg {
  margin-top: -3px;
}

.simple-text-button {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 600;
}
.button--blue {
  color: #2b84eb !important;
  cursor: pointer;
}
.button--red {
  color: #de2828 !important;
  cursor: pointer;
}
.button--gray {
  color: #757885;
  cursor: pointer;
}
</style>
