<template>
  <div
    :class="{
      'mr-md-2 operator-selector': true,
      'px-3 mt-1 mb-2': $vuetify.breakpoint.smAndDown,
    }"
  >
    <v-menu transition="slide-y-transition" bottom offset-y max-width="200px">
      <template #activator="{ on, attrs }">
        <v-btn
          class="operator-selector-btn elevation-0 px-0"
          height="30px"
          width="64px"
          dense
          v-bind="attrs"
          v-on="on"
        >
          {{ operator }}
          <v-icon right dark>
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>
      <v-list class="operator-selector-menu">
        <v-list-item-group v-model="operator">
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            :value="item"
            class="pl-2 pr-5 d-flex justify-center align-center"
          >
            <v-list-item-title>{{ item }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "OperatorSelector",
  props: {
    items: {
      default: () => ["AND", "OR"],
      type: Array,
    },
    value: {
      default: () => "AND",
      type: String,
    },
  },
  computed: {
    operator: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.operator-selector {
  button {
    min-height: 30px !important;
    border-radius: 4px;
    border: solid 1px #dee7ee !important;
    background-color: white !important;
    margin-bottom: 0;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-transform: none;
    color: #757885;

    span {
      color: #66788e;
    }
    &:before {
      opacity: 0 !important;
    }
    &[aria-expanded="true"] {
      span {
        color: #2b84eb;
      }

      border-radius: 4px;
      border: solid 1px #2b84eb !important;
    }
  }
}
.operator-selector-menu {
  .v-list-item {
    min-height: 37px;
    &:before {
      opacity: 0 !important;
    }
    &[aria-selected="true"] {
      background-color: #ebf7ff !important;
      i {
        color: #2b84eb;
      }
      .v-list-item__title {
        color: #2b84eb;
      }
    }
  }

  .v-list-item--link:hover {
    i {
      color: #2b84eb;
    }
    .v-list-item__title {
      color: #2b84eb;
    }
  }
  .v-list-item__title {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    cursor: pointer;
    color: black;
    transition: color 100ms ease-in-out;
  }
}
</style>
