<template>
  <div class="search-filters-container px-5 py-5">
    <div class="d-flex flex-nowrap justify-space-between pb-5">
      <div class="box-header">
        Filters
      </div>
      <div class="simple-text-button button--red d-flex align-center" @click="$emit('clear')">
        Clear All Filters
      </div>
    </div>

    <div class="pa-0 ma-0 d-flex flex-nowrap">
      <slot />
    </div>
    <slot name="actions" />
  </div>
</template>

<script>
export default {
  name: "SearchFiltersContainer",

  data: () => {
    return {
      items: [
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me 2" },
      ],
      conditionSelectors: ["is exactly", "contains"],
    };
  },
};
</script>
