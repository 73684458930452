<template>
  <div class="divider-mobile d-flex align-center justify-center mb-4 mt-2">
    <OperatorSelector :value="value" @input="$emit('input', $event)" />
  </div>
</template>

<script>
import OperatorSelector from "@/views/Contacts/components/inputs/OperatorSelector";

export default {
  name: "ConditionGroupsDividerMobile",
  components: {
    OperatorSelector,
  },
  props: {
    value: {
      default: () => "AND",
    },
  },
};
</script>

<style lang="scss" scoped>
.divider-mobile {
  width: 100%;
  margin: 0 16px;
  position: relative;
  z-index: 1;
}
.divider-mobile:after {
  position: absolute;
  left: 0;
  top: 50%;
  height: 1px;
  content: "";
  z-index: -1;
  background: #dee7ee;
  width: 100%;
  display: block;
}
</style>
