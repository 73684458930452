<template>
  <div
    :class="{
      'mr-md-1 entity-selector': true,
      'w-100 px-3 mb-1': $vuetify.breakpoint.smAndDown,
    }"
  >
    <v-menu transition="slide-y-transition" bottom offset-y max-width="400px">
      <template #activator="{ on, attrs }">
        <v-btn
          block
          class="field-selector-btn elevation-0 px-2"
          height="30px"
          dense
          v-bind="attrs"
          v-on="on"
        >
          {{ label || "Select a Field" }}
          <v-icon right dark>
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>
      <div class="entity-selector-menu">
        <div v-if="!entity">
          <div class="px-2 pt-2">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              clearable
              dense
              outlined
              placeholder="Search for filter"
              height="30px"
              hide-details
              @click.stop="stub"
              @input="onSearch"
            />
          </div>
          <v-list max-height="275">
            <v-list-item-group>
              <div v-if="!isSearching">
                <v-list-item
                  v-for="(item, i) in searched_entities"
                  :key="i"
                  class="px-5 d-flex justify-center align-center white"
                  @click.stop="select_entity(item.name)"
                >
                  <v-list-item-icon class="mr-4 my-2">
                    <v-icon size="16px">
                      {{ icons_associations[item.icon] }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ item.label }}</v-list-item-title>
                </v-list-item>
              </div>
              <div v-else>
                <v-list-item
                  v-for="(item, i) in searching_entities"
                  :key="i"
                  class="px-4 d-flex justify-center align-center white"
                  @click="select_field(item, item.name, item.entity)"
                >
                  <v-list-item-title>{{ item.label }} ({{ item.parentLabel }})</v-list-item-title>
                </v-list-item>
              </div>
            </v-list-item-group>
          </v-list>
        </div>
        <div v-if="entity">
          <v-list max-height="275">
            <v-list-item
              class="pl-1 pr-3 d-flex justify-start align-center selected-entity"
              @click.stop="select_entity('')"
            >
              <v-list-item-icon class="mr-4 my-2">
                <v-icon color="#2b84eb" size="23px">
                  mdi-chevron-left
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ searched_entities.filter(i => i.name === entity)[0].label }}</v-list-item-title>
            </v-list-item>
          </v-list>
          <v-divider />
          <v-list class="field-selector-menu" max-height="275">
            <v-list-item-group>
              <v-list-item
                v-for="(item, i) in selected_field.fields"
                :key="i"
                class="px-4 d-flex justify-center align-center white"
                @click="select_field(item, i)"
              >
                <v-list-item-title>{{ item.label }} ({{ selected_field.label }})</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "FieldSelector",
  props: {
    entities: {
      default: () => [],
      type: Array,
    },
    entity: {
      default: () => "",
      type: String,
    },
    selected_field: {
      default: () => {},
      type: Object,
    },
    fields: {
      default: () => {},
      type: Object,
    },
    field: {
      default: () => "",
      type: String,
    },
    label: {
      default: () => "",
      type: String,
    },
  },
  data() {
    return {
      search: "",
      icons_associations: {
        cursor: 'mdi-cursor-default-click-outline',
        person: 'mdi-account-outline',
        chart_line: 'mdi-chart-line',
        at_sign: 'mdi-at',
        device: 'mdi-laptop',
        envelope: 'mdi-email-outline',
        location: 'mdi-map-marker-outline',
        segment: 'mdi-account-multiple',
        star: 'mdi-star',
        calendar: 'mdi-calendar',
        form: 'mdi-form-select',
        telephone: 'mdi-phone',
        web: 'mdi-web',
        prescription: 'mdi-prescription'
      },
      searched_entities: {},
      searching_entities: {},
      isSearching: false
    }
  },
  created() {
    this.searched_entities = this.entities;
  },
  methods: {
    stub() {},
    onSearch() {
      if (this.search) {
        const searchResults = [];
        Object.entries(this.fields).forEach(([entity, item]) => {
          for(const [name, field] of Object.entries(item.fields)) {
            if(field.label.toLocaleLowerCase().includes(this.search.toLocaleLowerCase())) {
              searchResults.push({...field, parentLabel: item.label, name, entity: entity})
            }
          }
        })
        this.searching_entities = searchResults;
        this.isSearching = true;
        return;
      }
      this.isSearching = false
      this.searched_entities = this.entities;
    },
    select_entity(entity) {
      this.$emit("update:entity", entity);
      this.search = "";
      if (!entity) {
        this.select_field("");
      }
    },
    select_field(field, description, entity) {
      if(this.isSearching && entity) {
        this.$emit("update:entity", entity);
      }
      this.$emit("update:field", { field, description });
    },
  },
};
</script>

<style lang="scss" scoped>
.entity-selector {
  button {
    span {
      text-transform: none;
      font-family: "Open Sans", sans-serif;
      font-size: 13px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #66788e;
    }
    border-radius: 4px;
    border: solid 1px #dee7ee;
    background-color: #ecf1f5 !important;
    transition: background-color 20ms ease-in-out, border 20ms ease-in-out;
    &:before {
      opacity: 0 !important;
    }
    &[aria-expanded="true"] {
      span {
        color: #2b84eb;
      }

      border-radius: 4px;
      border: solid 1px #2b84eb !important;
      background-color: #ebf7ff !important;
    }
  }
}

.entity-selector-menu {
  min-width: 180px;
  /*For search field*/
  & > div {
    background-color: white;
  }
  .v-text-field__details {
    display: none;
  }
  ::v-deep .v-input__slot {
    min-height: 30px !important;
    padding-left: 6px !important;
  }
  i.v-icon {
    font-size: 18px;
  }
  ::v-deep .v-input__prepend-inner {
    margin-top: 4px !important;
  }
  ::v-deep .v-input__append-inner {
    margin-top: 4px !important;
  }
  ::v-deep .v-input__icon--prepend-inner {
    width: 18px;
  }
  ::v-deep .v-input__append-inner {
    width: 18px;
    button {
      font-size: 18px !important;
    }
  }
  ::v-deep .v-text-field__slot {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #757885;
  }
  /*For list*/
  .v-list-item {
    min-height: 37px;
    &:before {
      opacity: 0 !important;
    }
    &[aria-selected="true"] {
      background-color: #ebf7ff !important;
      i {
        color: #2b84eb;
      }
      .v-list-item__title {
        color: #2b84eb;
      }
    }
  }

  .v-list-item--link:hover {
    i {
      color: #2b84eb;
    }
    .v-list-item__title {
      color: #2b84eb;
    }
  }
  .v-list-item__title {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    cursor: pointer;
    color: black;
    transition: color 100ms ease-in-out;
  }
  .selected-entity {
    .v-list-item__title {
      font-family: "Open Sans", sans-serif;
      font-size: 13px;
      font-weight: 600;
      text-align: left;
      color: #241c15;
    }
  }
}

.field-selector-btn {
  text-transform: none;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #66788e;
}
</style>
