<template>
  <v-card class="popup-card">
    <v-card-title class="px-6 pt-4 pb-4">
      {{ segment.id ? 'Edit' : 'Create a' }} Segment
    </v-card-title>
    <v-divider />
    <v-card-text class="px-6 pt-5 pb-0">
      <validation-observer ref="textInput">
        <CustomTextInput
          v-model="name"
          :required="true"
          header="Segment Name"
          height="44px"
        />
      </validation-observer>
      <v-row>
        <v-col cols="12">
          <CustomFileUpload
            v-model="imageFile"
            placeholder="Select Preview Image"
            header="Preview Image"
            accept="image/*"
            hint=" Hint: Image should be 100 x 100 pixel for best results."
          />
        </v-col>
      </v-row>

      <CustomTextArea
        v-model="notes"
        header="Segment Notes"
        hide-details
        placeholder="Notes"
      />

      <template v-if="showDynamic">
        <div class="input-label mb-1 mt-6">
          Segment Type
        </div>
        <v-radio-group v-model="type" row>
          <v-radio
            value="1"
            label="Static"
            class="font-13px"
          />
          <v-radio
            value="2"
            label="Dynamic"
            class="font-13px"
          />
        </v-radio-group>
      </template>
      <template v-else>
        <v-alert
          outlined
          color="teal"
          text
          class="font-13px"
        >
          You can only create static segments on this page.
          To create a dynamic segment, please <a @click="goToContactSearchPage">perform a search</a> and create a segment on that page.
        </v-alert>
      </template>
    </v-card-text>
    <v-card-actions class="divider-with-shadow d-flex justify-end align-center px-6 py-3">
      <div
        class="simple-text-button button--gray px-9"
        @click="$emit('dismiss')"
      >
        Cancel
      </div>

      <v-btn
        class="custom-button custom-button--blue px-15"
        height="34px"
        depressed
        @click="on_save"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import CustomTextArea from "@/sharedComponents/CustomTextareaV2";
import CustomFileUpload from "@/sharedComponents/CustomFileUpload";

export default {
  name: "SegmentEdit",
  components: {
    CustomTextInput,
    CustomTextArea,
    CustomFileUpload,
  },
  props: {
    segment:{
      default: () => ({})
    },
    showDynamic: {
      default: () => true
    }
  },
  data(){
    return {
        name: "",
        type: "1",
        notes: "",
        image: null,
        imageFile: null,
      }
    },
  created(){
    this.name = this.segment.name
    this.type = this.segment.type ? this.segment.type : "1"
    this.notes = this.segment.notes
  },
  methods: {
    async on_save(){
      if (!await this.$refs.textInput.validate()) {
        return;
      }
      this.$emit('save', {
        id: this.segment.id,
        name: this.name,
        type: this.type,
        notes: this.notes,
        imageFile: this.imageFile,
      })
    },
    goToContactSearchPage() {
      this.$router.push({
        name: "ContactsSearch",
      });
    }
  },
};
</script>

<style lang="scss" scoped>
  .popup-card {
    .v-input.v-text-field {
      max-height: 44px;
    }
    .v-btn__content {
      text-transform: none;
    }
    .v-card__title {
      font-family: 'Open Sans', sans-serif;
      font-size: 15px !important;
      font-weight: 600 !important;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #241c15;
    }
    fieldset {
      border-radius: 4px;
      border: solid 1px #dee7ee !important;
      background-color: #ffffff;
    }
    .input-label {
      font-family: 'Open Sans', sans-serif;
      font-size: 13px !important;
      font-weight: 600 !important;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #241c15
    }
  }

  .font-13px {
    font-size: 13px;
  }

  ::v-deep .v-label {
    font-size: 13px !important;
  }
</style>
