<template>
  <div class="divider mt-3 mb-5">
    <OperatorSelector :value="value" @input="$emit('input', $event)" />
  </div>
</template>

<script>
import OperatorSelector from "@/views/Contacts/components/inputs/OperatorSelector";

export default {
  name: "ConditionGroupsDivider",
  components: {
    OperatorSelector,
  },
  props: {
    value: {
      default: () => "AND",
    },
  },
};
</script>

<style lang="scss" scoped>
.divider {
  position: relative;
  width: 100%;
  z-index: 1;
}
.divider:before,
.divider:after {
  position: absolute;
  left: 15px;
  height: 8px;
  width: 1px;
  content: "";
  z-index: -1;
  background: #e9edf0;
  display: block;
}

.divider:before {
  bottom: 115%;
}

.divider:after {
  top: 115%;
}
</style>
