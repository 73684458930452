<template>
  <div
    :class="{
      'mr-md-1 universal-choice-selector': true,
      'w-100 px-3 mb-1': $vuetify.breakpoint.smAndDown,
    }"
  >
    <v-menu transition="slide-y-transition" bottom offset-y>
      <template #activator="{ on, attrs }">
        <v-btn
          block
          class="choice-selector-btn elevation-0 px-2"
          height="30px"
          color="#dee7ee"
          dense
          v-bind="attrs"
          v-on="on"
        >
          {{ condition || "should..." }}
          <v-icon right dark>
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>
      <v-list class="universal-choice-selector-menu">
        <v-list-item-group>
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            class="px-4 d-flex justify-center align-center"
            @click="$emit('input', item)"
          >
            <v-list-item-title>{{ item }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "ConditionSelector",
  props: {
    items: {
      default: () => [1, 2, 3, 4],
      type: Array,
    },
    value: {
      default: () => "",
      type: String,
    },
  },
  computed: {
    condition: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  mounted(){
    if (!this.value) {
      this.$emit("input", this.items[0]);
    }
  },
};
</script>

<style lang="scss" scoped>
.universal-choice-selector {
  button {
    min-height: 30px !important;
    border-radius: 4px;
    border: solid 1px #dee7ee !important;
    background-color: #ecf1f5 !important;
    margin-bottom: 0;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-transform: none;
    span {
      color: #66788e;
    }
    &:before {
      opacity: 0 !important;
    }
    &[aria-expanded="true"] {
      span {
        color: #2b84eb;
      }

      border-radius: 4px;
      border: solid 1px #2b84eb !important;
    }
  }
}
.universal-choice-selector-menu {
  .v-list-item {
    min-height: 37px;
    &:before {
      opacity: 0 !important;
    }
    &[aria-selected="true"] {
      background-color: #ebf7ff !important;
      i {
        color: #2b84eb;
      }
      .v-list-item__title {
        color: #2b84eb;
      }
    }
  }

  .v-list-item--link:hover {
    i {
      color: #2b84eb;
    }
    .v-list-item__title {
      color: #2b84eb;
    }
  }
  .v-list-item__title {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    cursor: pointer;
    color: black;
    transition: color 100ms ease-in-out;
  }
}

.choice-selector-btn {
  text-transform: none;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #66788e;
}
</style>
